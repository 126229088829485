import cl from 'classnames';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Link } from './Link';
import OutlineArrowChevronRight from '@dolstaff/shared/es/icons/OutlineArrowChevronRight';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';

const underline = css`
  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 0;
    height: 2px;
    background-color: ${colorsV2.black100};
    transition: 0.2s ease-out;
  }
`;

const LinkText = styled.span`
  display: inline-block;
  position: relative;
  ${({ $isHeader }) => $isHeader && underline}
`;

const underlineHover = css`
  &:hover {
    ${LinkText}::after {
      width: 100%;
      left: 0;
    }
  }
`;

const Main = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  gap: 8px;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  color: #242938;
  .menu-item {
    height: 100%;
    display: flex;
    align-items: center;

    &.header {
      ${underlineHover}
    }
  }
  .disabled {
    color: ${colorsV2.neutral80};
  }
  .tool-tip {
    display: none;
    position: absolute;
    padding: 8px 12px;
    width: 127px;
    right: -135px;
    z-index: 10000;
    background: ${colorsV2.neutral180};
    border-radius: 12px;
  }
  &:hover {
    .disabled {
      color: ${colorsV2.neutral120};
    }
    .tool-tip {
      display: block;
    }
  }
`;

export const NavMenuItem = ({
  className,
  text,
  link,
  external,
  withIcon = false,
  isDisable = false,
  isHeader = false
}) => {
  if (external) {
    return (
      <Main
        className={cl('nav-menu-item', {
          'empty-link': !link
        })}
      >
        {link ? (
          <a
            key={text + link}
            href={`${link}`}
            target="_blank"
            rel="noopener noreferrer noffolow"
            className={cl('menu-item', className, { header: isHeader })}
          >
            {text}
            {withIcon && (
              <OutlineArrowChevronRight
                width={24}
                color={colorsV2.neutral100}
              />
            )}
          </a>
        ) : (
          <div className={cl('menu-item', className)}>
            {text}
            {withIcon && (
              <OutlineArrowChevronRight
                width={24}
                color={colorsV2.neutral100}
              />
            )}
          </div>
        )}
      </Main>
    );
  }

  return (
    <Main className={cl('nav-menu-item', { 'empty-link': !link })}>
      {link ? (
        <Link
          className={cl(
            'menu-item',
            className,
            { disabled: isDisable },
            { header: isHeader }
          )}
          key={text + link}
          to={link}
        >
          <LinkText $isHeader={isHeader}>{text}</LinkText>
          {withIcon && (
            <OutlineArrowChevronRight width={24} color={colorsV2.neutral100} />
          )}
          {isDisable && (
            <div className="tool-tip">
              <Typography variant="medium/14-20" color={colorsV2.white100}>
                Coming soon...
              </Typography>
            </div>
          )}
        </Link>
      ) : (
        <div className={cl('menu-item', className, { disabled: isDisable })}>
          <LinkText $isHeader={isHeader}>{text}</LinkText>
          {withIcon && (
            <OutlineArrowChevronRight width={24} color={colorsV2.neutral100} />
          )}
          {isDisable && (
            <div className="tool-tip">
              <Typography variant="medium/14-20" color={colorsV2.white100}>
                Coming soon...
              </Typography>
            </div>
          )}
        </div>
      )}
    </Main>
  );
};
